//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  position: relative;
}

main {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

section.copy-section {
  z-index: 1000;
  color: white;
  padding: 0 1rem;
  & h1 {
    padding: 0 7rem;
    font-size: 8rem;
    margin-bottom: 2rem;
    @include media-breakpoint-down(xxl) {
      font-size: 6rem;
      padding: 0 4rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 3.5rem;
      padding: 0 4rem;
      padding-top: 8rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 3.5rem;
      padding: 0 2rem;
      padding-top: 10rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 3rem;
      padding: 0 1rem;
    }
  }
  & h2 {
    font-size: 3.5rem;
    padding-left: 7rem;
    padding-right: 40rem;
    @include media-breakpoint-down(xxl) {
      font-size: 3rem;
      padding: 0 4rem;
    }
    @include media-breakpoint-down(lg) {
      font-size: 2rem;
      padding: 0 4rem;
    }
    @include media-breakpoint-down(md) {
      font-size: 2rem;
      padding: 0 2rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 2rem;
      padding: 0 1rem;
    }
  }
}

.logo-image-container {
  position: fixed;
  top: 80px;
  right: 80px;
  height: auto;
  width: 400px;
  @include media-breakpoint-down(xxl) {
    top: 20px;
    right: 20px;
    width: 300px;
  }
  @include media-breakpoint-down(sm) {
    top: 10px;
    right: 10px;
    width: 200px;
  }
}

.background-image-container {
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background: url(../images/shard-gradient.png) no-repeat center center fixed;
  background-size: cover;
}


